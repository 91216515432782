.overlay-1 {
  background: linear-gradient(76.78deg, rgba(34, 191, 234, 0.85) 22.46%, rgba(122, 225, 253, 0.765) 91.71%);
}

.overlay-2 {
  background: linear-gradient(50.78deg, rgba(34, 191, 234, 0.9) 28.4%, rgba(122, 225, 253, 0.693) 76.71%);
}

.overlay-3 {
  background: linear-gradient(44.38deg, rgba(34, 191, 234, 0.85) 24.33%, rgba(122, 225, 253, 0.748) 58.24%);
}

.overlay-4 {
  background: linear-gradient(76.78deg, rgba(34, 191, 234, 0.85) 22.46%, rgba(122, 225, 253, 0.85) 91.71%);
}

.overlay-5 {
  background: linear-gradient(76.78deg, rgba(34, 191, 234, 0.85) 22.46%, rgba(122, 225, 253, 0.85) 91.71%);
}

.overlay-6 {
  background: linear-gradient(42.59deg, rgba(34, 191, 234, 0.85) 34.04%, rgba(122, 225, 253, 0.7565) 74.99%);
}