@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat Medium'), local('Montserrat-Medium'),
  url('../fonts/Montserrat-Medium.woff2') format('woff2'),
  url('../fonts/Montserrat-Medium.woff') format('woff'),
  url('../fonts/Montserrat-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat Bold'), local('Montserrat-Bold'),
  url('../fonts/Montserrat-Bold.woff2') format('woff2'),
  url('../fonts/Montserrat-Bold.woff') format('woff'),
  url('../fonts/Montserrat-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat Regular'), local('Montserrat-Regular'),
  url('../fonts/Montserrat-Regular.woff2') format('woff2'),
  url('../fonts/Montserrat-Regular.woff') format('woff'),
  url('../fonts/Montserrat-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat SemiBold'), local('Montserrat-SemiBold'),
  url('../fonts/Montserrat-SemiBold.woff2') format('woff2'),
  url('../fonts/Montserrat-SemiBold.woff') format('woff'),
  url('../fonts/Montserrat-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

