@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @import 'fonts';
  @import '~@fortawesome/fontawesome-free/scss/brands';
  @import '~@fortawesome/fontawesome-free/scss/regular';
  @import '~@fortawesome/fontawesome-free/scss/solid';
  @import '~@fortawesome/fontawesome-free/scss/fontawesome';

  hr {
    @apply border-0 h-0.75 w-18 bg-blue-light mx-auto mt-5 mb-4;
  }

  a,
  button,
  input {
    @apply transition duration-300 ease-in-out
      outline-none focus:outline-none;
  }

  a, button {
    @apply hover:opacity-80 focus:opacity-60;
  }

  #map .leaflet-tile-pane {
    @apply filter grayscale;
  }
}

@layer components {
  @import "components/text";
  @import "components/form";
  @import "components/button";
  @import "components/overlays";
}