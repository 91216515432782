.btn {
  @apply inline-block font-bold text-black bg-transparent rounded-full
    border border-solid border-transparent
    cursor-pointer select-none text-base leading-none tracking-wider py-3.5 px-6
    hover:opacity-90 focus:opacity-80;
}

.btn-orange {
  @apply bg-orange text-white border-orange;
}

.btn-blue {
  background: linear-gradient(87.97deg, #22BFEA 11.57%, #6AEDFF 103.58%);
  @apply text-white;
}


.carousel-button {
  @apply hidden 1200:flex 1200:items-center 1200:justify-center absolute inset-y-0 my-auto w-10 h-10 rounded-full bg-blue text-white leading-10 text-center z-10;

  &.carousel-button-prev {
    @apply left-56;
  }

  &.carousel-button-next {
    @apply right-56;
  }
}


.carousel-dot {
  @apply text-blue text-dot mx-3;

  &.carousel-dot-active {
    @apply text-blue-light text-xl mx-2;
  }
}