.form-input {
  @apply block w-full py-3.5 pr-6 pl-16
    text-base leading-none font-medium text-black bg-gray-light rounded-full
    border border-solid border-gray-dark
    appearance-none bg-clip-padding;
}

.form-label {
  @apply absolute top-4 left-6;
}

.form-checkbox {
  @apply hidden;
}

.form-label-checkbox {
  @apply flex items-center font-medium text-sm;

  &:before {
    content: '';
    @apply flex-none w-4.5 h-4.5 border border-solid border-gray-dark bg-gray-light rounded-sm mr-2.5 text-xs text-center;
  }
}

.form-checkbox:checked ~ .form-label-checkbox:before {
  content: '\f00c';
  @apply font-icons font-black bg-blue text-white border-blue leading-4;
}